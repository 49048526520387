import React from 'react';

const EmaliaSVG = ({ className = "w-12 h-12" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
    <rect width="24" height="24" rx="4" fill="#FF4B91" />
    <path d="M4 8l8 5 8-5v8H4V8z" fill="white" />
    <path d="M4 8l8 5 8-5" fill="none" stroke="white" strokeWidth="1.5" />
  </svg>
);

const Logo = ({ showText = true, className = "" }) => (
  <div className={`flex items-center ${className}`}>
    <EmaliaSVG />
    {showText && <span className="text-2xl font-bold text-gray-800 ml-2">Emalia</span>}
  </div>
);

export default Logo;