import React, { useState, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getUserProfile } from '../services/api';

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isConfigured, setIsConfigured] = useState(null);
  const [isPromptConfigured, setIsPromptConfigured] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsAuthenticated(false);
        return;
      }

      try {
        const response = await getUserProfile();
        setIsAuthenticated(true);
        setIsConfigured(response.data.is_configured);
        setIsPromptConfigured(!!response.data.prompt && response.data.prompt.trim() !== '');
      } catch (error) {
        setIsAuthenticated(false);
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
      }
    };

    checkAuth();
  }, [location.pathname]); // Re-check when the path changes

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (!isConfigured && location.pathname !== '/email-config') {
    return <Navigate to="/email-config" replace />;
  }

  if (isConfigured && !isPromptConfigured && location.pathname !== '/prompt-config' && location.pathname !== '/dashboard') {
    return <Navigate to="/prompt-config" replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;