import React, { useEffect, useState } from "react";
import { getUserProfile, updatePrompt } from "../services/api";
import Navbar from "./Navbar";

const Profile = () => {
  const [user, setUser] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await getUserProfile();
      setUser(response.data);
      setPrompt(response.data.prompt || "");
    } catch (err) {
      setError("Failed to fetch user profile");
    }
  };

  const handleUpdatePrompt = async (e) => {
    e.preventDefault();
    try {
      await updatePrompt(prompt);
      setSuccess("Prompt updated successfully");
      setError("");
    } catch (err) {
      setError(err.response?.data?.message || "Failed to update prompt");
      setSuccess("");
    }
  };

  if (!user) return <div className="flex justify-center items-center h-screen">Loading...</div>;

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Navbar />
      <div className="flex-grow container mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h2 className="text-3xl font-bold mb-6 text-gray-800">User Profile</h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {success && <p className="text-green-500 mb-4">{success}</p>}
            <div className="mb-6">
              <p className="text-lg"><strong>Email:</strong> {user.email}</p>
            </div>
            <form onSubmit={handleUpdatePrompt}>
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-lg font-semibold mb-2"
                  htmlFor="prompt"
                >
                  ChatGPT Prompt
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="prompt"
                  rows="6"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="Enter your custom ChatGPT prompt here..."
                />
              </div>
              <div className="flex items-center justify-end">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                  type="submit"
                >
                  Update Prompt
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
