import DOMPurify from "dompurify";
import { convert } from "html-to-text";
import sanitizeHtml from "sanitize-html";
import {
  ArrowLeft,
  ArrowRight,
  Mail,
  Inbox,
  RefreshCw,
  Send,
  ThumbsUp,
  User,
  X,
  Mic,
  StopCircle,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { fetchEmails, generateResponse, sendResponse, transcribeAudio } from "../services/api";
import Navbar from "./Navbar";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";

const EmaliaSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className="w-8 h-8"
  >
    <rect width="24" height="24" rx="4" fill="#FF4B91" />
    <path d="M4 8l8 5 8-5v8H4V8z" fill="white" />
    <path d="M4 8l8 5 8-5" fill="none" stroke="white" strokeWidth="1.5" />
  </svg>
);

const EmailResponseApp = () => {
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [suggestion, setSuggestion] = useState("");
  const [generatedResponse, setGeneratedResponse] = useState("");
  const [editableResponse, setEditableResponse] = useState("");
  const [isWaitingForApproval, setIsWaitingForApproval] = useState(false);
  const [notification, setNotification] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioStream, setAudioStream] = useState(null);
  const [audioRecorder, setAudioRecorder] = useState(null);

  useEffect(() => {
    fetchEmailsData();
  }, []);

  const fetchEmailsData = async () => {
    setIsLoading(true);
    try {
      const storedThreads = JSON.parse(
        localStorage.getItem("emailThreads") || "[]"
      );
      const storedLastFetchTime = localStorage.getItem("lastFetchTime");

      setThreads(storedThreads);
      setLastFetchTime(storedLastFetchTime);

      const response = await fetchEmails(storedLastFetchTime);
      const newEmails = response.data.emails;
      const newLastFetchTime = response.data.last_fetch_time;

      if (newEmails.length > 0) {
        const updatedThreads = groupEmailsIntoThreads([
          ...storedThreads,
          ...newEmails,
        ]);
        setThreads(updatedThreads);
        localStorage.setItem("emailThreads", JSON.stringify(updatedThreads));
      }

      setLastFetchTime(newLastFetchTime);
      localStorage.setItem("lastFetchTime", newLastFetchTime);
    } catch (error) {
      console.error("Error fetching emails:", error);
      setNotification({
        type: "error",
        message:
          "Error al obtener los correos electrónicos. Por favor, inténtalo de nuevo.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const LoadingSpinner = () => (
    <div className="flex flex-col justify-center items-center h-full">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500 mb-4"></div>
      <p className="text-lg text-gray-600 font-semibold">
        Cargando tus correos electrónicos...
      </p>
    </div>
  );

  const groupEmailsIntoThreads = (emails) => {
    const threadMap = new Map();

    // Flatten the array of emails if necessary
    const flatEmails = emails.flat();

    // First pass: create threads
    flatEmails.forEach((email) => {
      const threadId = email.message_id;
      if (!threadMap.has(threadId)) {
        threadMap.set(threadId, [email]);
      } else {
        threadMap.get(threadId).push(email);
      }
    });

    // Second pass: link replies to their parent threads
    threadMap.forEach((thread, threadId) => {
      thread.forEach((email) => {
        if (email.in_reply_to) {
          const parentId = email.in_reply_to.trim();
          if (threadMap.has(parentId)) {
            // Move this email to the parent thread
            threadMap.get(parentId).push(email);
            // Remove it from the current thread
            const index = threadMap.get(threadId).indexOf(email);
            if (index > -1) {
              threadMap.get(threadId).splice(index, 1);
            }
          }
        }
      });
    });

    // Remove empty threads
    for (let [threadId, thread] of threadMap.entries()) {
      if (thread.length === 0) {
        threadMap.delete(threadId);
      }
    }

    // Sort emails within each thread by date
    threadMap.forEach((thread) => {
      thread.sort((a, b) => new Date(a.date) - new Date(b.date));
    });

    // Convert map to array and sort threads by the date of the most recent email
    return Array.from(threadMap.values())
      .filter((thread) => thread.length > 0)
      .sort(
        (a, b) =>
          new Date(b[b.length - 1].date) - new Date(a[a.length - 1].date)
      );
  };

  const handleSelectThread = (thread) => {
    setSelectedThread(thread);
    setSuggestion("");
    setGeneratedResponse("");
    setEditableResponse("");
    setIsWaitingForApproval(false);
    setIsSidebarOpen(false);
  };

  const handleSuggestionChange = (e) => {
    setSuggestion(e.target.value);
  };

  const handleGenerateResponse = async () => {
    setIsGenerating(true);
    try {
      const lastMessage = selectedThread[selectedThread.length - 1];
      const response = await generateResponse(lastMessage.body, suggestion);
      setGeneratedResponse(response.data.response);
      setEditableResponse(response.data.response);
      setIsWaitingForApproval(true);
    } catch (error) {
      console.error("Error generating response:", error);
      setNotification({
        type: "error",
        message: "Error al generar la respuesta. Por favor, inténtalo de nuevo.",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRegenerateResponse = async () => {
    setIsGenerating(true);
    setIsWaitingForApproval(false);
    setGeneratedResponse("");
    setEditableResponse("");
    await handleGenerateResponse();
  };

  const handleEditResponse = (e) => {
    setEditableResponse(e.target.value);
  };

  const handleApproveResponse = async () => {
    setIsSending(true);
    try {
      const lastMessage = selectedThread[selectedThread.length - 1];
      const response = await sendResponse(
        lastMessage.id,
        editableResponse,
        lastMessage.sender,
        lastMessage.message_id,
        lastMessage.references
      );

      if (response.status === 200) {
        const newMessageId = response.data.message_id;
        setNotification({
          type: "success",
          message: "¡Respuesta enviada exitosamente!",
        });
        const updatedThread = [
          ...selectedThread,
          {
            id: newMessageId,
            body: editableResponse,
            sender: "You",
            date: new Date().toISOString(),
            is_sent: true,
            message_id: newMessageId,
            in_reply_to: lastMessage.message_id,
            references: lastMessage.references
              ? `${lastMessage.references} ${lastMessage.message_id}`
              : lastMessage.message_id,
          },
        ];
        setSelectedThread(updatedThread);
        setThreads((prevThreads) => {
          const updatedThreads = prevThreads.map((thread) =>
            thread[0].message_id === updatedThread[0].message_id
              ? updatedThread
              : thread
          );
          return groupEmailsIntoThreads(updatedThreads);
        });
        setSuggestion("");
        setGeneratedResponse("");
        setEditableResponse("");
        setIsWaitingForApproval(false);
      } else {
        throw new Error("La respuesta del servidor no fue exitosa");
      }
    } catch (error) {
      console.error("Error sending response:", error);
      setNotification({
        type: "error",
        message: "Error al enviar la respuesta. Por favor, inténtalo de nuevo.",
      });
    } finally {
      setIsSending(false);
    }
  };

  const shortenLink = (url) => {
    try {
      const urlObject = new URL(url);
      const domain = urlObject.hostname;
      const path = urlObject.pathname;
      if (path.length > 15) {
        return `${domain}/...${path.slice(-10)}`;
      }
      return `${domain}${path}`;
    } catch (e) {
      return url;
    }
  };

  const renderEmailBody = (body) => {
    // Reemplazar caracteres de escape y decodificar caracteres especiales
    const decodedBody = body
      .replace(/\\r\\n/g, '\n')
      .replace(/\\n/g, '\n')
      .replace(/\\"/g, '"')
      .replace(/\\/g, '')
      .replace(/\u00f3/g, 'ó')
      .replace(/\u00e1/g, 'á')
      .replace(/\u00e9/g, 'é')
      .replace(/\u00ed/g, 'í')
      .replace(/\u00fa/g, 'ú');

    // Verificar si el contenido es HTML basado en elementos específicos como DOCTYPE
    const isHtml =
      decodedBody.includes('<!DOCTYPE html>') ||
      /<html|<head|<body|<div|<span|<a|<p|<br/i.test(decodedBody);

    if (isHtml) {
      // Si es HTML, sanitizarlo y renderizarlo
      const sanitizedHtml = sanitizeHtml(decodedBody, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'style']),
        allowedAttributes: {
          '*': ['style', 'class', 'target', 'href', 'src', 'alt'],
        },
        allowProtocolRelative: true,
      });

      return {
        __html: DOMPurify.sanitize(sanitizedHtml),
        isHtml: true,
      };
    } else {
      // Si es texto plano, formatearlo y convertir URLs en enlaces
      const plainTextContent = convert(decodedBody, {
        wordwrap: 130,
        preserveNewlines: true, // Preserva los saltos de línea en el texto plano
      });

      const processedPlainText = plainTextContent
        .split('\n')
        .map((line) => {
          const lineWithLinks = line.replace(/(https?:\/\/[^\s]+)/g, (url) => {
            const shortUrl = shortenLink(url);
            return `<a href="${url}" class="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer">${shortUrl}</a>`;
          });
          return `<p>${lineWithLinks}</p>`;
        })
        .join('');

      return {
        __html: processedPlainText,
        isHtml: false,
      };
    }
  };

  const EmailBody = ({ body }) => {
    const { __html, isHtml } = renderEmailBody(body);

    return (
      <div
        dangerouslySetInnerHTML={{ __html }}
        className={`email-body ${isHtml ? 'html-content' : 'plain-text'}`}
      />
    );
  };

  const emailStyles = `
    .email-body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
    }
    .email-body.plain-text p {
      margin: 0;
      padding: 0;
      min-height: 1em;
      white-space: pre-wrap; /* Preserva los saltos de línea y espacios */
    }
    .email-body a {
      color: #3b82f6;
      text-decoration: none;
    }
    .email-body a:hover {
      text-decoration: underline;
    }
  `;

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setAudioStream(stream);
      const recorder = new MediaRecorder(stream);
      setAudioRecorder(recorder);
      
      const chunks = [];
      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = async () => {
        const audioBlob = new Blob(chunks, { type: 'audio/wav' });
        await handleAudioTranscription(audioBlob);
      };

      recorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
      setNotification({
        type: "error",
        message: "Error al iniciar la grabación. Por favor, inténtalo de nuevo.",
      });
    }
  };

  const stopRecording = () => {
    if (audioRecorder) {
      audioRecorder.stop();
      setIsRecording(false);
    }
    if (audioStream) {
      audioStream.getTracks().forEach(track => track.stop());
    }
  };

  const handleAudioTranscription = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.wav');
      const response = await transcribeAudio(formData);
      setSuggestion(prevSuggestion => prevSuggestion + ' ' + response.data.transcription);
    } catch (error) {
      console.error("Error transcribing audio:", error);
      setNotification({
        type: "error",
        message: "Error al transcribir el audio. Por favor, inténtalo de nuevo.",
      });
    }
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <Navbar />
      <div className="flex flex-1 overflow-hidden relative">
        <button
          className="lg:hidden fixed left-4 z-30 bg-white p-2 rounded-md shadow-md"
          style={{ top: "calc(64px + 1rem)" }} // Adjust this value based on your Navbar height
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? <X /> : <Inbox />}
        </button>

        <aside
          className={`
          ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0
          fixed lg:relative inset-y-0 left-0 z-20
          w-64 lg:w-80 bg-white shadow-md
          transition-transform duration-300 ease-in-out
          overflow-y-auto
          mt-16 lg:mt-0 // Add top margin for mobile to account for Navbar
        `}
        >
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-4">Threads</h3>
            {threads.map((thread, index) => (
              <div
                key={index}
                className={`p-3 mb-2 rounded-lg cursor-pointer flex items-center
                  ${
                    selectedThread === thread
                      ? "bg-blue-50 border border-blue-200"
                      : "hover:bg-gray-50"
                  }
                `}
                onClick={() => handleSelectThread(thread)}
              >
                <div className="flex-shrink-0 mr-3">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {thread[0].sender}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {thread[0].subject}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </aside>

        <main className="flex-1 overflow-y-auto bg-white lg:ml-4 p-4">
          {isLoading ? (
            <LoadingSpinner />
          ) : selectedThread ? (
            <div className="max-w-3xl mx-auto">
              <h2 className="text-2xl font-semibold mb-4">
                {selectedThread[0].subject}
              </h2>
              {selectedThread.map((email) => (
                <div
                  key={email.message_id}
                  className={`mb-6 ${email.is_sent ? "ml-8" : "mr-8"}`}
                >
                  <div className="mb-2 flex items-center">
                    {email.is_sent ? (
                      <ArrowRight className="h-5 w-5 text-blue-500 mr-2" />
                    ) : (
                      <ArrowLeft className="h-5 w-5 text-gray-500 mr-2" />
                    )}
                    <User className="h-8 w-8 text-gray-400 bg-gray-100 rounded-full p-1 mr-2" />
                    <div>
                      <p className="font-medium">{email.sender}</p>
                      <p className="text-sm text-gray-500">
                        {new Date(email.date).toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <div
                    className={`p-4 rounded-lg ${
                      email.is_sent ? "bg-blue-50" : "bg-gray-50"
                    }`}
                  >
                    <EmailBody body={email.body} />
                    <style>{emailStyles}</style>
                  </div>
                </div>
              ))}
              <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 mt-6">
                <div className="flex items-center mb-4">
                  <textarea
                    className="flex-grow p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                    rows="3"
                    value={suggestion}
                    onChange={handleSuggestionChange}
                    placeholder="Escribe una sugerencia para la respuesta..."
                    disabled={isWaitingForApproval || isGenerating || isRecording}
                  />
                  <button
                    className={`ml-2 p-2 rounded-full ${
                      isRecording ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-600'
                    } hover:bg-opacity-80 transition duration-150`}
                    onClick={isRecording ? stopRecording : startRecording}
                    disabled={isWaitingForApproval || isGenerating}
                  >
                    {isRecording ? (
                      <StopCircle className="h-6 w-6" />
                    ) : (
                      <Mic className="h-6 w-6" />
                    )}
                  </button>
                </div>
                {!isWaitingForApproval && (
                  <div className="flex justify-end">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-blue-600 transition duration-150 disabled:opacity-50"
                      onClick={handleGenerateResponse}
                      disabled={!suggestion || isGenerating}
                    >
                      {isGenerating ? (
                        <RefreshCw className="mr-2 animate-spin" size={16} />
                      ) : (
                        <Send className="mr-2" size={16} />
                      )}
                      {isGenerating ? "Generando..." : "Generar Respuesta"}
                    </button>
                  </div>
                )}
                {isWaitingForApproval && (
                  <div className="mt-4">
                    <h3 className="font-semibold mb-2">
                      Respuesta Generada (Editable):
                    </h3>
                    <textarea
                      className="w-full p-3 mb-4 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent resize-none"
                      rows="6"
                      value={editableResponse}
                      onChange={handleEditResponse}
                    />
                    <div className="flex justify-end space-x-2">
                      <button
                        className="bg-green-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-green-600 transition duration-150 disabled:opacity-50"
                        onClick={handleApproveResponse}
                        disabled={isGenerating || isSending}
                      >
                        {isSending ? (
                          <RefreshCw className="mr-2 animate-spin" size={16} />
                        ) : (
                          <ThumbsUp className="mr-2" size={16} />
                        )}
                        {isSending ? "Enviando..." : "Aprobar y Enviar"}
                      </button>
                      <button
                        className="bg-yellow-500 text-white px-4 py-2 rounded-lg flex items-center hover:bg-yellow-600 transition duration-150 disabled:opacity-50"
                        onClick={handleRegenerateResponse}
                        disabled={isGenerating || isSending}
                      >
                        {isGenerating ? (
                          <RefreshCw className="mr-2 animate-spin" size={16} />
                        ) : (
                          <RefreshCw className="mr-2" size={16} />
                        )}
                        {isGenerating ? "Regenerando..." : "Regenerar"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="text-center text-gray-500 mt-20">
              Selecciona un hilo para ver los mensajes
            </div>
          )}
        </main>
      </div>

      {notification && (
        <Alert
          className="fixed bottom-4 right-4 max-w-sm"
          variant={notification.type === "error" ? "destructive" : "default"}
        >
          <AlertTitle>
            {notification.type === "error" ? "Error" : "Éxito"}
          </AlertTitle>
          <AlertDescription>{notification.message}</AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default EmailResponseApp;
