import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generatePrompt } from '../services/api';
import Navbar from './Navbar';

const PromptConfig = () => {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [role, setRole] = useState('');
  const [tone, setTone] = useState('');
  const [emailExamples, setEmailExamples] = useState('');
  const [suggestions, setSuggestions] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const promptData = { name, company, role, tone, emailExamples, suggestions };
    
    // Enviar la solicitud sin esperar la respuesta
    generatePrompt(promptData).catch(err => {
      console.error('Error during prompt configuration:', err);
      // Opcionalmente, puedes manejar el error de alguna manera, 
      // pero no afectará la redirección al dashboard
    });

    // Redirigir inmediatamente al dashboard
    navigate('/dashboard');
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Navbar />
      <div className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="max-w-4xl mx-auto">
          <form onSubmit={handleSubmit} className="bg-white shadow-xl rounded-lg px-8 pt-6 pb-8 mb-4">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">Configura tu Asistente de IA</h2>
            {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Tu Nombre
                </label>
                <input
                  className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                  Nombre de la Empresa
                </label>
                <input
                  className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  id="company"
                  type="text"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
                  Tu Cargo
                </label>
                <input
                  className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  id="role"
                  type="text"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="tone">
                  Tono de Comunicación Preferido
                </label>
                <select
                  className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  id="tone"
                  value={tone}
                  onChange={(e) => setTone(e.target.value)}
                  required
                >
                  <option value="">Selecciona un tono</option>
                  <option value="formal">Formal</option>
                  <option value="amistoso">Amistoso</option>
                  <option value="casual">Casual</option>
                  <option value="profesional">Profesional</option>
                </select>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="emailExamples">
                Ejemplos de Emails que Sueles Enviar
              </label>
              <textarea
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="emailExamples"
                rows="5"
                value={emailExamples}
                onChange={(e) => setEmailExamples(e.target.value)}
                placeholder="Ingresa algunos ejemplos de emails que sueles enviar..."
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="suggestions">
                Sugerencias Adicionales
              </label>
              <textarea
                className="shadow-sm appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                id="suggestions"
                rows="3"
                value={suggestions}
                onChange={(e) => setSuggestions(e.target.value)}
                placeholder="Ingresa cualquier sugerencia adicional que tengas para el asistente..."
              />
            </div>
            <div className="flex items-center justify-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full focus:outline-none focus:shadow-outline transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                type="submit"
              >
                Guardar Configuración
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PromptConfig;