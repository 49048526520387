import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import EmailConfig from './components/EmailConfig';
import PromptConfig from './components/PromptConfig';
import Profile from './components/Profile';
import EmailResponseApp from './components/EmailResponseApp';
import PrivateRoute from './components/PrivateRoute';

const App = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/email-config" element={<EmailConfig />} />
            <Route path="/prompt-config" element={<PromptConfig />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<EmailResponseApp />} />
          </Route>
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;