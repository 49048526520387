import { LogOut } from 'lucide-react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from './Logo';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <nav className="bg-indigo-600">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center ml-4">
            <div className="flex-shrink-0">
              <Logo showText={false} />
            </div>
            <div className="ml-4">
        <h2 className="text-xl font-bold text-white">Emalia</h2>
      </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <Link to="/dashboard" className="text-white hover:bg-indigo-500 px-3 py-2 rounded-md text-sm font-medium">Dashboard</Link>
                <Link to="/profile" className="text-white hover:bg-indigo-500 px-3 py-2 rounded-md text-sm font-medium">Profile</Link>
                <Link to="/email-config" className="text-white hover:bg-indigo-500 px-3 py-2 rounded-md text-sm font-medium">Email Config</Link>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <button onClick={handleLogout} className="text-white hover:bg-indigo-500 px-3 py-2 rounded-md text-sm font-medium flex flex-row"> <LogOut /></button>
          </div>
          <div className="-mr-2 flex md:hidden">
            <button onClick={() => setIsOpen(!isOpen)} type="button" className="bg-indigo-600 inline-flex items-center justify-center p-2 rounded-md text-white hover:text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-600 focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              {!isOpen ? (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg> 
              ) : (
                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                
              )}
            </button>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/dashboard" className="text-white hover:bg-indigo-500 block px-3 py-2 rounded-md text-base font-medium">Dashboard</Link>
            <Link to="/profile" className="text-white hover:bg-indigo-500 block px-3 py-2 rounded-md text-base font-medium">Profile</Link>
            <Link to="/email-config" className="text-white hover:bg-indigo-500 block px-3 py-2 rounded-md text-base font-medium">Email Config</Link>
            <button onClick={handleLogout} className="text-white hover:bg-indigo-500 block w-full text-left px-3 py-2 rounded-md text-base font-medium">Logout</button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;